import { render, staticRenderFns } from "./newMenu.vue?vue&type=template&id=378cca42&scoped=true"
import script from "./newMenu.vue?vue&type=script&lang=js"
export * from "./newMenu.vue?vue&type=script&lang=js"
import style0 from "./newMenu.vue?vue&type=style&index=0&id=378cca42&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "378cca42",
  null
  
)

export default component.exports