<template>
  <el-carousel
    height="480px"
    arrow="never"
    class="banner"
    :indicator-position="currentBanner.length > 1 ? '' : 'none'"
  >
    <el-carousel-item
      v-for="(item, index) in currentBanner"
      :key="'banner' + index"
    >
      <el-image class="banner-box image" :src="item.image" :alt="item.title"  fit="cover">
      </el-image>
      <el-image
        class="banner-box min-image" 
        :src="item.minImage"
        :alt="item.title"
        fit="cover"
      >

      </el-image>
      <div class="banner-title left">
        <h2 v-for="text in item.title" :key="text">{{ text }}</h2>
        <div class="desc">{{ item.desc }}</div> 
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: 'AboutBanner',
  data() {
    return {
      pathUrl: '',
      currentBanner: [], // 当前页面banner
      bannerList: [
        {
          banner: [
            {
              image:
                'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-bg-01.png',
              minImage:
                'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-bg-min-01.png',
              title: ['使命：构建数字营销新基建，推动数字商业新文明','愿景：成为世界级的软件服务企业'],
              desc: '',
            },
          ],
        },
      ],
    };
  },

  mounted() {
    this.getCurrentBanner();

    console.log(this.currentBanner, 'currentBanner');
  },
  methods: {
    getCurrentBanner() {
      this.currentBanner = this.bannerList[0].banner;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  ::v-deep .el-carousel--horizontal {
    overflow-x: none !important;
  }
}
.banner-box {
  width: 100vw;
  height: 480px;
  position: relative;

  // background-size: 100% 480px;
  // background-position: 50%;
  // background-repeat: no-repeat;
}
.banner-title {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-top: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h2 {
    font-size: 24px;
    font-weight: 500;
    color: #2D2E30;
    padding-bottom: 24px;
    text-align: left;
  }
  .desc {
    font-size: 16px;
    line-height: 28px;
    color: #5a5c60;
    width: 550px;
    text-align: left;
  }
}

.banner {
  .min-image {
    display: none;
  }
  ::v-deep .el-carousel__indicators--horizontal {
    width: 1180px;
    bottom: 20px;
    .el-carousel__indicator {
      &.is-active {
        .el-carousel__button {
          background: #3777f6;
        }
      }
    }
    left: 20%;
    display: none;
  }
  ::v-deep .el-carousel__button {
    display: block;
    opacity: 1;
    width: 36px;
    height: 3px;
    background: #fff;
    position: relative;
  }
}
@media screen and (min-width: 1920px) {
  .el-carousel__item {
    text-align: center;
    background: #e5efff;
  }
  .banner {
    .image {
      width: 1920px !important;
    }

  }
}

// 移动端布局
@import '@/views/about/min-about';
</style>
